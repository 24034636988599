import cc from 'classcat';
import React, { InputHTMLAttributes, ReactElement, ReactNode } from 'react';
import { Form } from 'react-bulma-components';
import { Path, RegisterOptions, useFormContext } from 'react-hook-form';

interface TextFieldProps<TFormValues extends FormValues>
  extends InputHTMLAttributes<HTMLInputElement> {
  Caption?: ReactNode;
  id: string;
  label: string;
  name: Path<TFormValues>;
  validation?: RegisterOptions<TFormValues>;
}

const TextField = <TFormValues extends FormValues = FormValues>({
  Caption,
  className,
  id,
  label,
  name,
  validation = {},
  ...remainingProps
}: TextFieldProps<TFormValues>): ReactElement => {
  const formContext = useFormContext<TFormValues>();

  const {
    formState: { errors },
    register,
  } = formContext;

  const error = errors[name];
  const errorMessage = Array.isArray(error) ? error?.[0].message : error?.message;

  return (
    <Form.Field>
      <Form.Label htmlFor={id}>{label}</Form.Label>
      <input
        className={cc([className, 'input', { 'is-danger': !!error }])}
        id={id}
        {...remainingProps}
        {...register(name, validation)}
      />
      {errorMessage && <Form.Help color="danger">{errorMessage}</Form.Help>}
      {Caption && <Form.Help>{Caption}</Form.Help>}
    </Form.Field>
  );
};

export default TextField;
